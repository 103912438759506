import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faMap } from '@fortawesome/free-regular-svg-icons/faMap';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons. Note: you also need to re-run 'npm install' if adding new icons

const data = [
  {
    link: 'mailto:pwjebel@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
  {
    link: 'https://www.asg.ed.tum.de/sipeo/team/patrick-ebel/',
    label: 'Position',
    icon: faMap,
  },
  {
    link: 'https://scholar.google.com/citations?user=vOSVVdkAAAAJ',
    label: 'Scholar',
    icon: faGoogle,
  },
  {
    link: 'https://twitter.com/pwjebel',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'https://github.com/patrickTUM',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.linkedin.com/in/pwjebel',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
];

export default data;
