import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.png`} alt="" />
      </Link>
      <header>
        <h2>Patrick W. J. Ebel</h2>
        <p><a href="mailto:pwjebel@gmail.com">pwjebel@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Patrick. I am a postdoctoral scientist at the European Space Agency in Italy, where my focus is on Earth observation for extreme weather scenarios.
      
      If not busy coding, I enjoy cooking, dancing Tango Argentino, or doing Yoga.
      </p>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Patrick Ebel <Link to="/">pwjebel.com</Link>. 
      A fork of <a href="https://github.com/mldangelo/personal-site">mldangelo/personal-site</a></p>
    </section>
  </section>
);

export default SideBar;
