const routes = [
  {
    index: true,
    label: "About",
    path: '/',
  },
  {
    label: 'Research',
    path: '/research',
  },
  {
    label: 'Publicity',
    path: '/publicity',
  },
  {
    label: 'Extracurricular',
    path: '/extracurricular',
  },
];

export default routes;
